import { BreadcrumbService } from './shared/utils/breadcrumb.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AuthService } from './core/core/services/auth.service';
import { PwaService } from './core/core/services/pwa.service';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  auditTime,
  concatMap,
  delay,
  filter,
  map,
  skip,
  switchMap,
  take,
  takeLast,
  tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BrowserService } from './core/core/services/browser.service';
import { LANG } from './shared/interface/lang';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'driveHellas';
  url!: string;
  env = environment;
  espa: any = [
    // {
    //   img: {
    //     en: '/assets/images/espa/etpa_english.jpg',
    //     el: '/assets/images/espa/etpa_greek.jpg',
    //   },
    //   link: {
    //     en: 'https://static.drive-hellas.com/images/uploads/improvement.pdf',
    //     el: 'https://static.drive-hellas.com/images/uploads/improvement.pdf',
    //   },
    // },
    {
      img: {
        en: '/assets/images/espa/epanek_english.jpg',
        el: '/assets/images/espa/epanek_greek.jpg',
      },
      link: {
        en: 'https://static.drive-hellas.com/images/uploads/espa-pdf-english.pdf',
        el: 'https://static.drive-hellas.com/images/uploads/espa-pdf.pdf',
      },
    },
    // {
    //   img: {
    //     en: '/assets/images/espa/drive-ee-logo.jpg',
    //     el: '/assets/images/espa/drive-ee-logo.jpg',
    //   },
    //   link: {
    //     en: 'https://static.drive-hellas.com/images/uploads/ee.pdf',
    //     el: 'https://static.drive-hellas.com/images/uploads/ee.pdf',
    //   },
    // },
  ];
  lang!: LANG;
  breadcrumbs$: Observable<
    {
      url: string;
      label: any;
      active: boolean;
    }[]
  > = this.BreadcrumbService.breadcrumbs$.pipe(
    filter((data) => data.length && isPlatformBrowser(this.platformId)),
    auditTime(300),
    tap((breadcrumbs) => this.createBreadcrumbSchema(breadcrumbs))
  );
  constructor(
    private translateService: TranslateService,
    private BreadcrumbService: BreadcrumbService,
    private PwaService: PwaService,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private BrowserService: BrowserService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.translateService.onLangChange.subscribe((change) => {
      this.lang = change.lang as LANG;
      this.BrowserService.setLocalStorage('lang', this.lang);
    });
  }

  // menuMode = 'overlay';

  ngOnInit() {
    this.updateCurrentRoute().subscribe();
    this.langBasicConfig();

    this.PwaService.checkForUpdatesAndReload();
    this.AuthService.initiateUser();
  }
  scrollTop(data: any) {
    this.BrowserService.scrollTo(0, 0);
  }

  updateCurrentRoute() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap((event: any) => {
        this.url = event.url;
        this.BreadcrumbService.configureBreadcrumbs(event);
      })
    );
  }
  langBasicConfig() {
    const savedLang = this.BrowserService.getLocalStorage('lang') || 'el';
    this.translateService.setDefaultLang(environment.defaultLanguage || 'el');

    this.translateService.use(savedLang);
    this.translateService.addLangs(['en']);

    this.lang = this.translateService.currentLang as LANG;
  }

  createBreadcrumbSchema(breadcrumbs: any) {
    if (!isPlatformBrowser(this.platformId)) return;

    const itemList =
      this.BreadcrumbService.returnStringifiedBreadCrumbItemList(breadcrumbs);
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
 {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": ${itemList}
    }
        `;
    console.log(script);
    this._renderer2.appendChild(this._document.body, script);
  }
}
