import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { FrontpageService } from './../frontpage.service';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from './../../../core/core/services/seo.service';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BrowserService } from 'src/app/core/core/services/browser.service';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css'],
})
export class FrontpageComponent implements OnInit {
  lang = 'el';
  topOffer$!: Observable<any>;
  bottomOffer$!: Observable<any>;
  searchForm!: UntypedFormGroup;
  seoContent: any = {
    title: {
      en: 'Rent A Car In Greece - Car Rental Company',
      el: 'Αρχική Σελίδα',
    },
    description: {
      en: 'Rent A Car In Greece - Car Rental Company',
      el: 'Ανακάλυψε αυτοκίνητα και βαν προς ενοικίαση ή leasing σε μοναδικές προσφορές στην Drive Hellas και επωφελήσου!',
    },
  };
  constructor(
    private seo: SeoService,
    private translate: TranslateService,
    private FrontpageService: FrontpageService,
    private fb: UntypedFormBuilder,
    private _renderer2: Renderer2,
    private BrowserService: BrowserService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.translate.onLangChange.subscribe((data) => {
      this.lang = data.lang;
      this.seo.setTitle(this.seoContent.title[this.lang]);
      this.seo.setDescription(this.seoContent.description[this.lang]);
    });
  }

  ngOnInit(): void {
    this.createOrganizationSchema();
    this.BrowserService.scrollTo(0, 0);
    this.lang = this.translate.currentLang;
    this.seo.setTitle(this.seoContent.title[this.lang]);
    this.seo.setDescription(this.seoContent.description[this.lang]);
    this.setSearchFiltersForm();
    this.getFrontPageOffers();
  }
  setSearchFiltersForm() {
    const value = JSON.parse(sessionStorage.getItem('searchFilters') || '{}');
    const { pickupTime, returnTime, pickupDate, returnDate, pickupLocation } =
      value;
    const savedPickUp = new Date(pickupDate);
    const savedReturn = new Date(returnDate);
    this.searchForm = this.fb.group({
      pickupTime: [pickupTime ? pickupTime : '09:00', [Validators.required]],
      returnTime: [returnTime ? pickupTime : '09:00', [Validators.required]],
      pickupDate: [
        pickupDate
          ? savedPickUp
          : new Date(new Date().setDate(new Date().getDate() + 2)),
        [Validators.required],
      ],
      returnDate: [
        returnDate
          ? savedReturn
          : new Date(new Date().setDate(new Date().getDate() + 3)),
        [Validators.required],
      ],
      pickupLocation: [
        pickupLocation || { name: { el: '', en: '' }, code: '' },
        [Validators.required],
      ],
    });
    // console.log('FrontPage Form', this.searchForm.value);
  }

  getFrontPageOffers() {
    this.topOffer$ = this.FrontpageService.getFrontPageOffers(
      'frontPage',
      'top'
    );
    this.bottomOffer$ = this.FrontpageService.getFrontPageOffers(
      'frontPage',
      'bottom'
    );
  }
  createOrganizationSchema() {
    if (!isPlatformBrowser(this.platformId)) return;
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
         {
      "@context": "https://schema.org",
      "@type": "Organization",
      "image": "https://static.drive-hellas.com/logo.jpg",
      "url": "https://www.drive-hellas.com/",
      "sameAs": ["https://www.facebook.com/drivehellaspage", "https://twitter.com/drivehellas","https://www.instagram.com/drivehellas","https://www.tiktok.com/@drivehellas"],
      "logo": "https://static.drive-hellas.com/logo.jpg",
      "name": "Drive Hellas",
      "description": "Drive Hellas - Car Rental Company",
      "email": "rentacar@drive-hellas.gr",
      "telephone": "+30 2310 472600",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "7,5 χλμ. Οδού Θεσσαλονίκης-Μουδανιών",
        "addressLocality": "Thessaloniki",
        "addressCountry": "GR",
        "addressRegion": "Central Macedonia",
        "postalCode": "57001"
      },
      "vatID": "EL099770081
"
    }

    `;

    this._renderer2.appendChild(this._document.body, script);
  }
}
