import { map, tap } from 'rxjs/operators';
import { PagesService } from './../../../pages/pages.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { BrowserService } from './browser.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private pService: PagesService,
    private BrowserService: BrowserService
  ) {}

  currentUserValue() {
    return this.currentUserSubject.value;
  }
  initiateUser() {
    const user = this.getUserFromLocalStorage();
    if (!user) return;
    this.currentUserSubject.next(user);
  }

  getUserFromLocalStorage() {
    return JSON.parse(
      this.BrowserService.getLocalStorage('currentUser') || '{}'
    );
  }
  setUserToLocalStorage(user: any) {
    this.BrowserService.setLocalStorage('currentUser', JSON.stringify(user));
  }

  login(body: any) {
    return this.pService.post('auth/login', body).pipe(
      map((user: any) => {
        this.setUserToLocalStorage(user);
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    this.BrowserService.removeLocalStorage('currentUser');
    this.BrowserService.removeLocalStorage('myReservations');
    this.currentUserSubject.next(null);
  }
  register(body: any) {
    return this.pService.post('users', body);
  }
}
